<template>
  <v-tabs
    v-model="tab"
    vertical
    background-color="red lighten-5"
  >
    <template v-for="t in teams">
      <v-tab
        v-if="isTeam(t.team._id) || isStbWWk(id)"
        :href="`#team-${t.team._id}`"
        :key="t.team._id"
      >
        {{ t.team.name }}
      </v-tab>
    </template>

    <template v-for="t in teams">
      <v-tab-item
        :value="`team-${t.team._id}`"
        :key="t.team._id"
      >
        <v-tabs
          v-model="tabverein"
          grow
          show-arrows
          background-color="grey"
          color="white"
        >
          <v-tab href="#trainer">Mannschaftsführer/Trainer<br>({{ t.team.name }})</v-tab>
          <v-tab href="#start">Startaufstellung<br>({{ t.team.name }})</v-tab>
          <v-tab href="#geraete">Geräteaufstellung<br>({{ t.team.name }})</v-tab>

          <v-tab-item value="trainer">
            <trainer :id="id" :team="t.team._id" :e="e" :df="df" />
          </v-tab-item>
          <v-tab-item value="start">
            <start :id="id" :team="t.team._id" :e="e" :df="df" />
          </v-tab-item>
          <v-tab-item value="geraete">
            <geraete
              :id="id"
              :team="t.team._id"
              :e="e"
              :df="df"
            />
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </template>
  </v-tabs>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import { useAuth } from '@/plugins/auth'
import { mapGetters } from 'vuex'

export default {
  name: 'mannschaften',

  components: {
    Trainer: () => import('./mannschaften/trainer'),
    Start: () => import('./mannschaften/start'),
    Geraete: () => import('./mannschaften/geraete')
  },

  setup (props, context) {
    return {
      ...useAuth(context),
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    tab: 0,
    tabverein: 'trainer'
  }),

  computed: {
    ...mapGetters(['isStbWWk']),
    teams () {
      return this.e?.teams || []
    }
  }
}
</script>

<style scoped>

</style>
